import React, { useRef } from "react";
import Link from "next/link";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Autoplay, Thumbs } from "swiper/modules";

// import bannerImgOne from "../../public/images/banner/banner-small-01.png";
// import bannerImgTwo from "../../public/images/banner/banner-small-02.png";
// import bannerImgThree from "../../public/images/banner/banner-small-03.png";

const MenfisBanner = () => {
  const thumbsSwiperRef = useRef(null);

  return (
    <>
      <Swiper
        className="swiper rbt-banner-activation rbt-slider-animation rbt-arrow-between"
        modules={[FreeMode, Navigation, Autoplay ]}
        ref={thumbsSwiperRef}
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoHeight={true}
        navigation={{
          nextEl: ".rbt-arrow-right",
          prevEl: ".rbt-arrow-left",
          clickable: true,
        }}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        // thumbs={{ swiper: thumbsSwiperRef.current }}
      >
        <div className="swiper-wrapper">
          <SwiperSlide className="swiper-slide">
            <div
              className="rbt-banner-area rbt-banner-6 variation-03 bg_image bg_menfis_image--6"
              data-gradient-overlay="5"
            >
              <div className="wrapper w-100">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <div className="section-title">
                          <span className="subtitle bg-white-opacity d-inline-block">
                            Colegio Menfis
                          </span>
                        </div>
                        <h1 className="title w-700">
                          ¡Anímate! El proceso de admisión para el 2025 ya está en marcha.
                        </h1>
                        <div className="button-group mt--30">
                          <Link
                            className="rbt-btn btn-border hover-icon-reverse color-white"
                            href="https://zfrmz.com/6HMLcUimM7qDfX0jgdUE"
                            target="_blamk"
                          >
                            <div class="icon-reverse-wrapper">
                              <span class="btn-text" data-text="More About University">
                                Reserva tu cupo
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                                </span>
                              </div>
                          </Link>
                        </div>
                        <div className="social-share-wrapper mt--40">
                          <ul className="social-icon social-default transparent-with-border">
                            <li>
                              <Link href="https://www.facebook.com/colmenfischinchina" target="_blank">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.instagram.com/colegiomenfis" target="_blank">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                          <span className="follow-us-text">
                            Follow By Facebook and Instagram, 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div
              className="rbt-banner-area rbt-banner-6 variation-03 bg_image bg_menfis_image--8"
              data-gradient-overlay="5"
            >
              <div className="wrapper w-100">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <div className="section-title">
                          <span className="subtitle bg-white-opacity d-inline-block">
                            Aule.co
                          </span>
                        </div>
                        <h1 className="title w-700">
                          ¡Tenemos nueva Plataforma Educativa!  <br />{" "}
                          <strong className="color-white">
                            
                          </strong>
                        </h1>
                        <div className="button-group mt--30">
                          <Link
                            className="rbt-btn btn-border hover-icon-reverse color-white"
                            href="https://colegiomenfis.aule.co/"
                            target="_blank"
                          >
                            <div class="icon-reverse-wrapper">
                              <span class="btn-text" data-text="More About University">
                                Ingresa ahora
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                                </span>
                              </div>
                          </Link>
                        </div>
                        <div className="social-share-wrapper mt--40">
                          <ul className="social-icon social-default transparent-with-border">
                            <li>
                              <Link href="https://www.facebook.com/colmenfischinchina" target="_blank">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.instagram.com/colegiomenfis" target="_blank">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                          <span className="follow-us-text">
                            Follow By Facebook and Instagram, 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div
              className="rbt-banner-area rbt-banner-6 variation-03 bg_image bg_menfis_image--7"
              data-gradient-overlay="5"
            >
              <div className="wrapper w-100">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <div className="section-title">
                          <span className="subtitle bg-white-opacity d-inline-block">
                            English Immersion Camp
                          </span>
                        </div>
                        <h1 className="title w-700">
                          ¡Inscripciones abiertas para nuestro  <br />{" "}
                          <strong className="color-white">
                            Campamento de Inglés 2024!
                          </strong>
                        </h1>
                        <div className="button-group mt--30">
                          <Link
                            className="rbt-btn btn-border hover-icon-reverse color-white"
                            href="https://wa.me/+573235055710"
                            target="_blank"
                          >
                            <div class="icon-reverse-wrapper">
                              <span class="btn-text" data-text="More About University">
                                Inscríbete
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                                </span>
                              </div>
                          </Link>
                        </div>
                        <div className="social-share-wrapper mt--40">
                          <ul className="social-icon social-default transparent-with-border">
                            <li>
                              <Link href="https://www.facebook.com/colmenfischinchina" target="_blank">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.instagram.com/colegiomenfis" target="_blank">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                          <span className="follow-us-text">
                            Follow By Facebook and Instagram, 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div
              className="rbt-banner-area rbt-banner-6 variation-03 bg_image bg_menfis_image--5"
              data-gradient-overlay="5"
            >
              <div className="wrapper w-100">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <div className="section-title">
                          <span className="subtitle bg-white-opacity d-inline-block">
                            Mattilda
                          </span>
                        </div>
                        <h1 className="title w-700">
                          ¡Estrenamos plataforma de pagos!
                        </h1>
                        <div className="button-group mt--30">
                          <Link
                            className="rbt-btn btn-border hover-icon-reverse color-white"
                            href="/mattilda"
                            target="_self"
                          >
                            <div class="icon-reverse-wrapper">
                              <span class="btn-text" data-text="More About University">
                                Conócela
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                                </span>
                              </div>
                          </Link>
                        </div>
                        <div className="social-share-wrapper mt--40">
                          <ul className="social-icon social-default transparent-with-border">
                            <li>
                              <Link href="https://www.facebook.com/colmenfischinchina" target="_blank">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.instagram.com/colegiomenfis" target="_blank">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                          <span className="follow-us-text">
                            Follow By Facebook and Instagram, 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="swiper-slide">
            <div
              className="rbt-banner-area rbt-banner-6 variation-03 bg_image bg_menfis_image--2"
              data-gradient-overlay="5"
            >
              <div className="wrapper w-100">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <div className="section-title">
                          <span className="subtitle bg-white-opacity d-inline-block">
                            Colegio Menfis
                          </span>
                        </div>
                        <h1 className="title w-700">
                          Ser Menfisiano es <br />{" "}
                          <strong className="color-white">
                            Lograr un mundo mejor 
                          </strong>
                        </h1>
                        <div className="button-group mt--30">
                          <Link
                            className="rbt-btn btn-border hover-icon-reverse color-white"
                            href="/about-us"
                          >
                            <div class="icon-reverse-wrapper">
                              <span class="btn-text" data-text="More About University">
                                Conócenos
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                                </span>
                              </div>
                          </Link>
                        </div>
                        <div className="social-share-wrapper mt--40">
                        <ul className="social-icon social-default transparent-with-border">
                            <li>
                              <Link href="https://www.facebook.com/colmenfischinchina" target="_blank">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.instagram.com/colegiomenfis" target="_blank">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                          <span className="follow-us-text">
                            Follow By Facebook and Instagram, 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div
              className="rbt-banner-area rbt-banner-6 variation-03 bg_image bg_menfis_image--1"
              data-gradient-overlay="5"
            >
              <div className="wrapper w-100">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <div className="section-title">
                          <span className="subtitle bg-white-opacity d-inline-block">
                            Colegio Menfis
                          </span>
                        </div>
                        <h1 className="title w-700">
                          Lugar de aprendizajes <br />{" "}
                          <strong className="color-white">
                            Significativos 
                          </strong>
                        </h1>
                        <div className="button-group mt--30">
                          <Link
                            className="rbt-btn btn-border hover-icon-reverse color-white"
                            href="/enrollment"
                          >
                            <div class="icon-reverse-wrapper">
                              <span class="btn-text" data-text="More About University">
                                Proceso de admisión
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                              </span>
                              <span class="btn-icon">
                                <i class="feather-arrow-right"></i>
                                </span>
                              </div>
                          </Link>
                        </div>
                        <div className="social-share-wrapper mt--40">
                        <ul className="social-icon social-default transparent-with-border">
                            <li>
                              <Link href="https://www.facebook.com/colmenfischinchina" target="_blank">
                                <i className="feather-facebook"></i>
                              </Link>
                            </li>
                            <li>
                              <Link href="https://www.instagram.com/colegiomenfis" target="_blank">
                                <i className="feather-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                          <span className="follow-us-text">
                            Follow By Facebook and Instagram, 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </div>
        <div className="rbt-swiper-arrow rbt-arrow-left">
          <div className="custom-overfolow">
            <i className="rbt-icon feather-arrow-left"></i>
            <i className="rbt-icon-top feather-arrow-left"></i>
          </div>
        </div>

        <div className="rbt-swiper-arrow rbt-arrow-right">
          <div className="custom-overfolow">
            <i className="rbt-icon feather-arrow-right"></i>
            <i className="rbt-icon-top feather-arrow-right"></i>
          </div>
        </div>
      </Swiper>

      {/* <Swiper
        className="swiper rbt-swiper-thumb rbtmySwiperThumb"
        ref={thumbsSwiperRef}
        onSwiper={(swiper) => {
          thumbsSwiperRef.current = swiper;
        }}
        spaceBetween={10}
        slidesPerView={2}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        <div className="swiper-wrapper">
          <SwiperSlide className="swiper-slide">
            <Image
              src={bannerImgOne}
              width={72}
              height={41}
              alt="Banner Images"
            />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <Image
              src={bannerImgTwo}
              width={72}
              height={41}
              alt="Banner Images"
            />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <Image
              src={bannerImgThree}
              width={72}
              height={41}
              alt="Banner Images"
            />
          </SwiperSlide>
        </div>
      </Swiper> */}
    </>
  );
};

export default MenfisBanner;
